<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <FormItem>
        <span>产品名称：</span>
        <Select placeholder="请选择" clearable filterable class="iviewIptWidth250 marginRight60" v-model="queryFrom.product_name">
          <Option v-for="(item, index) in productList" :value="item.name" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>产品编号：</span>
        <i-input v-model.trim="queryFrom.product_model_code" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
      </FormItem>
      <FormItem>
        <span>申请单号：</span>
        <i-input v-model="queryFrom.apply_order_code" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
      </FormItem>
      <FormItem>
        <span>医院订单号：</span>
        <i-input v-model="queryFrom.ebeln" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
      </FormItem>
      <FormItem>
        <span>客户名称：</span>
        <Select class="iviewIptWidth250 marginRight60" v-model="clientIndex" clearable filterable>
          <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <FormItem style="marginleft: 33px;">
        <span>状态：</span>
        <Select class="iviewIptWidth250" v-model="queryFrom.apply_status" clearable>
          <Option v-for="item in applyStatusList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem>
      <template v-if="moreChange">
        <FormItem style="marginLeft: 60px;">
          <span>申请时间：</span>
          <DatePicker class="iviewIptWidth250" placeholder="请选择" :value="queryFrom.apply_time_begin" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker>
          <span class="choseTimeZhi">至</span>
          <DatePicker :options="options" class="iviewIptWidth250" :value="queryFrom.apply_time_end" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
        </FormItem>
        <br />
        <FormItem style="marginleft: 15px;">
          <span>申请者：</span>
          <i-input v-model="queryFrom.afnam" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
        </FormItem>
        <FormItem style="marginleft: 20px;">
          <span>采购组：</span>
          <i-input v-model="queryFrom.ekgrp" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
        </FormItem>
        <FormItem style="marginleft: 30px;">
          <span>备注：</span>
          <i-input v-model="queryFrom.potxt" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
        </FormItem>
      </template>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="moreChange = !moreChange">{{ moreChange ? '收起' : '更多' }}</span>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
      </FormItem>
    </Form>
    <Table :loading="isLoad" @on-sort-change="sortChange" :columns="listColumns" :data="listData" border highlight-row :row-class-name="rowClassName">
      <template slot-scope="{ row }" slot="product_name_cut">
        <Poptip word-wrap width="200" :content="row.product_name_str" trigger="hover" placement="top">
          <span>{{ row.product_name_cut }}</span>
        </Poptip>
      </template>
    </Table>
    <div class="summary" v-if="listData.length > 0">
      <span style="margin-right: 20px;"
        >总数量：<span class="color389">{{ totalNum }}</span></span
      >
      <span>合计金额：</span>
      <span class="color389">¥{{ totalMoney }}</span>
    </div>
    <div class="tabPages" v-if="listData.length > 0 && total">
      <Page :total="total" :current="queryFrom.page" :page-size="queryFrom.rows" @on-change="changePage" class="fl" />
      <div class="fr" style="font-size: 14px;">共计{{ total }}条记录</div>
    </div>
    <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0 && !isLoad">
      <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
    </div>
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TipsComponent from '@/components/tipsComponent'
export default {
  components: {
    TipsComponent,
  },
  computed: {
    ...mapState(['clientNameList']),
  },
  data() {
    return {
      moreChange: false,
      productList: [],
      paging: null,
      clickId: null,
      status: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 4,
      typeList: [],
      options: {},
      deleteStatus: false,
      totalNum: '',
      totalMoney: '',
      clientIndex: '-1',
      applyStatusList: [
        // {
        //   value: '-1',
        //   label: '草稿',
        // },
        {
          value: '0',
          label: '待审核',
        },
        {
          value: '1',
          label: '已审核',
        },
      ],
      isLoad: true,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '申请单号',
          align: 'center',
          key: 'apply_order_code',
          width: 200,
          sortable: 'custom',
          render: (h, param) => {
            if (param.row.transfer_tag.length != 0) {
              return h('div', [
                h('img', {
                  style: {
                    cursor: 'pointer',
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                  },
                  attrs: {
                    src: require(`../../assets/images/${param.row.transfer_tag.join(',').replace(/,/gi, '')}.png`),
                  },
                }),
                h(
                  'span',
                  {
                    style: {
                      color: param.row.retpo?'#fa3e3b':'#389AFC',
                      lineHeight: '49px',
                      display: 'inline-block',
                      height: '49px',
                      verticalAlign: 'top',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row, 1)
                      },
                    },
                  },
                  param.row.apply_order_code
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: param.row.retpo?'#fa3e3b':'#389AFC',
                      lineHeight: '49px',
                      display: 'inline-block',
                      height: '49px',
                      verticalAlign: 'top',
                      cursor: 'pointer',
                      // marginLeft: '40px'
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row, 1)
                      },
                    },
                  },
                  param.row.apply_order_code
                ),
              ])
            }
          },
        },
        {
          title: '医院订单号',
          key: 'ebeln',
          align: 'center',
          width: 120,
        },
        {
          title: '客户名称',
          align: 'center',
          key: 'customer_name',
          width: 220,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.customer_name
              ),
            ])
          },
        },
        {
          title: '产品',
          align: 'center',
          minWidth: 250,
          key: 'product_name_cut',
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.product_name_cut
              ),
            ])
          },
        },
        {
          title: '数量',
          key: 'product_amount',
          align: 'center',
          width: 90,
        },
        {
          title: '金额',
          align: 'center',
          key: 'product_price',
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [h('span', param.row.product_price ? '¥' + param.row.product_price : '0')])
          },
          width: 130,
        },
        {
          title: '申请单时间',
          key: 'applyTime',
          sortable: 'custom',
          align: 'center',
          width: 150,
        },
        {
          title: '申请者',
          key: 'afnam',
          align: 'center',
          width: 130,
        },
        {
          title: '采购组',
          key: 'ekgrp',
          align: 'center',
          width: 130,
        },
        {
          title: '备注',
          key: 'potxt',
          align: 'center',
          width: 130,
        },
        {
          title: '状态',
          key: 'apply_status',
          align: 'center',
          width: 90,
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 220,
          render: (h, param) => {
            let status = param.row.apply_status
            if (status == '草稿') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row, 2)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                      // marginLeft: '40px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                      marginLeft40: !this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            } else if (status == '待审核') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row, 2)
                      },
                    },
                  },
                  '审核'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.deleteAble,
                      marginLeft40: !this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.apply_order_code
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '已审核') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row, 2)
                      },
                    },
                  },
                  '审核'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                      marginLeft: '40px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.apply_order_code
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.apply_order_code
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
          },
        },
      ],
      listData: [],
      total: 1,
      queryFrom: {
        afnam: '',
        ekgrp: '',
        potxt: '',
        product_name: '',
        product_model_code: '',
        page: 1,
        rows: 10,
        apply_order_code: '',
        ebeln: '',
        customer_type: '',
        customer_id: '',
        apply_time_begin: '',
        apply_time_end: '',
        apply_status: '',
        sort_str: '',
        sort_type: '',
      },
      apply_order_code: '', // 暂存点击的code
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      // 第一次进页面
      flag: false,
      searchData: {},
    }
  },
  //当进入组件之前，执行 beforRouteEnter 路由钩子函数
  // beforeRouteEnter(to,from,next){
  //   if(from.path != '/salesApplicationEdit') {
  //     sessionStorage.removeItem('search')
  //     sessionStorage.removeItem('page')
  //   }
  //   next()
  // },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.query()
      sessionStorage.setItem('updataCache', '1')
    }
    if (localStorage.getItem('sousuo')) {
      this.queryFrom = JSON.parse(localStorage.getItem('sousuo'))
      this.queryList()
    }
  },
  mounted() {
    if (this.$route.query.show) {
      this.queryFrom.apply_status = '0'
    }
    // this.searchData = JSON.parse(sessionStorage.getItem('search')) || []
    // this.queryFrom.page = Number(JSON.parse(sessionStorage.getItem('page'))) || 1
    // if(this.searchData.length == 0) {
    //   this.flag = true
    // } else {
    //   Object.assign(this.queryFrom,this.searchData)
    // }
    this.query()
    this.getRightVisibal()
    this.queryProduct()
    // this.flag = true
  },
  methods: {
    // 查询产品列表和sku列表
    queryProduct(id = null, subId = null) {
      this.$http.get(this.$api.getProductNameSelect, { product_name: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        // if (!subId) {
        this.productList = res.data
        // } else {
        // this.modelList = res.data
        // }
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (String(row.product_amount).indexOf('.') > -1) return 'redRow'
      if (row.retpo) return 'redRow'
      return ''
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$api.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$api.recentTemplate, { template_type: this.template_type }, true).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$api.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 打印模板
    surePrint() {
      let api_info = `${this.$api.saleApplyOperate.url}?apply_order_code=${this.clickId}`
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$api.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '销售申请单.xlsx'
        aLink.click()
      })
    },
    sortChange(data) {
      console.log(data)
      if (data.key === 'applyTime') {
        this.queryFrom.sort_str = 'apply_time'
      } else {
        this.queryFrom.sort_str = data.key
      }
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$api.saleApply, { apply_order_code: this.apply_order_code }, true).then(res => {
        this.query()
        this.deleteStatus = false
      })
    },
    // 点击删除
    deleteRow(row) {
      this.apply_order_code = row.apply_order_code
      this.deleteStatus = true
    },
    // 点击查询
    query() {
      this.queryFrom.page = 1
      // this.queryFrom.page = 1
      this.queryList()
    },
    queryList() {
      this.isLoad = true
      // if(!this.flag) {
      //   this.queryFrom = this.searchData
      // } else {
      if (this.clientIndex >= 0) {
        this.queryFrom.customer_id = this.clientNameList[this.clientIndex].customer_id
        this.queryFrom.customer_type = this.clientNameList[this.clientIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      // }
      // sessionStorage.setItem('search',JSON.stringify(this.queryFrom))
      console.log(this.queryFrom)
      this.$http.get(this.$api.saleApply, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.totalNum = res.total_quantity
        this.totalMoney = res.total_price
        this.total = res.total
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].applyTime = this.$moment(res.data[i].apply_time * 1000).format('YYYY-MM-DD')
        }
        this.listData = res.data
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.apply_time_begin = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.apply_time_begin) - 86400000
          },
        }
      } else {
        this.queryFrom.apply_time_end = e
      }
    },
    // 查看详情
    goDetails(row, num) {
      localStorage.setItem('sousuo', JSON.stringify(this.queryFrom))
      this.$router.push({
        path: '/salesApplicationEdit',
        query: {
          id: row.apply_order_code,
          type: num, //  1是查看详情 2是编辑
          triName: num == 1 ? '详情' : '编辑',
        },
      })
    },

    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>

<style scoped lang="less">
.item {
  margin-bottom: 20px;
}
.label {
  margin-left: 32px;
}
.marginRight60 {
  margin-right: 60px;
}
.tabPages {
  margin-top: 25px;
  overflow: hidden;
  line-height: 32px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
/deep/ .marginLeft40 {
  margin-left: 40px;
}
.pagePadding /deep/ .ivu-table td,
.ivu-table th {
  padding: 0px !important;
  position: relative !important;
}
// .pagePadding /deep/ .ivu-table-cell{
//   padding-left: 0px !important;
// }
</style>
